import { Component, OnInit } from "@angular/core";
import { UserService } from "src/app/services";
import { ToastrManager } from "ng6-toastr-notifications";
import { ErrorService } from "src/app/services/error.service";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";

@Component({
  selector: "app-edit-modal-userbyrole",
  templateUrl: "./edit-modal-userbyrole.component.html",
  styleUrls: ["./edit-modal-userbyrole.component.css"],
})
export class EditModalUserbyroleComponent implements OnInit {
  model: any = {};
  isView_Edit: string;
  modalObj: any;

  constructor(
    private userService: UserService,
    public toastr: ToastrManager,
    private errorService: ErrorService,
    public activeModal: NgbActiveModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.isView_Edit = this.model.action;
    this.modalObj = {
      firstName: this.model.item.firstName,
      id: this.model.item.id,
      lastName: this.model.item.lastName,
      mobileno: this.model.item.mobileno,
      emailAddress: this.model.item.emailAddress,
      address: this.model.item.organisationAddress,
      registration_number: this.model.item.organisation_reg_no,
      organisation_identification: this.model.item.organisation_identification,
      organisation_logo: this.model.item.organisation_logo,
    };
  }

  getFilename(fullPath) {
    return fullPath.substring(fullPath.lastIndexOf("/") + 1);
  }

  onOrganisationIdentificationSelect(event) {
    this.userService
      .updateOrganisationIdentification(event.target.files[0])
      .subscribe((res: any) => {
        this.modalObj.organisation_identification =
          res.organisation_identification;
      });
  }

  onOrganisationLogoSelect(event) {
    this.userService
      .updateOrganisationLogo(event.target.files[0])
      .subscribe((res: any) => {
        this.modalObj.organisation_logo = res.organisation_logo;
      });
  }

  onSubmit() {
    const formData = new FormData();

    formData.append("firstName", this.modalObj.firstName);
    formData.append("lastName", this.modalObj.lastName);
    formData.append("mobileno", this.modalObj.mobileno);
    formData.append("emailAddress", this.modalObj.emailAddress);
    formData.append("organisation_reg_no", this.modalObj.registration_number);
    // formData.append('organisation_identification',this.modalObj.organisation_identification)
    this.userService.updateUsers(this.modalObj).subscribe((t) => {
      if (!!t && t.constructor === Object) {
        this.activeModal.dismiss();
        this.toastr.successToastr("Succesfully update Users  !");
        location.reload();
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return true;
        };
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }
}
