import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from "ng6-toastr-notifications";
import { UserService } from "../../services/index";
import { ErrorService } from 'src/app/services/error.service';

@Component({
  selector: "app-modal",
  templateUrl: "./modal.component.html",
  styleUrls: ["./modal.component.css"]
})
export class ModalComponent implements OnInit {
  model: any = {};
  update_details:any={};
  constructor(
    public activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private userService: UserService,
    public toastr: ToastrManager,
    private errorService: ErrorService
  ) {}

  ngOnInit() {
   
  }

  onAmenitiesSubmit(){
      this.update_details={
        amenities: this.model.amenities,
        id:this.model.id
      }
    this.userService.updateAmenities(this.update_details).subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.toastr.successToastr("Succesfully updated Ameninities !");
        this.activeModal.dismiss();
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }
}
