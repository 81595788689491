import * as _ from "lodash";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "dataFilter",
})
export class DataFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();

    return items.filter((it) => {
      return it.userName.toLowerCase().includes(searchText);
    });
  }
}

//TODO : User Search Roles wise filter
@Pipe({
  name: "rolesFilter",
})
export class RoleFilterPipe implements PipeTransform {
  transform(
    items: any[],
    emailSearch: string,
    nameSearch: string,
    lastnameSearch: string,
    mobileSearch: string,
    registrationSearch: string
  ) {
    if (items && items.length) {
      return items.filter((item) => {
        if (
          emailSearch &&
          item.emailAddress.toLowerCase().indexOf(emailSearch.toLowerCase()) ===
            -1
        ) {
          return false;
        }

        // name search in USER
        if (
          nameSearch &&
          item.firstName.toLowerCase().indexOf(nameSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          lastnameSearch &&
          item.lastName.toLowerCase().indexOf(lastnameSearch.toLowerCase()) ===
            -1
        ) {
          return false;
        }
        if (
          mobileSearch &&
          item.mobileNumber
            .toLowerCase()
            .indexOf(mobileSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        // regeration Number in bus management
        if (
          registrationSearch &&
          item.bus_reg_no
            .toLowerCase()
            .indexOf(registrationSearch.toLowerCase()) === -1
        ) {
          return false;
        }

        return true;
      });
    } else {
      return items;
    }
  }
}

// booker filter
@Pipe({
  name: "bookerFilter",
})
export class DataBookerFilter implements PipeTransform {
  transform(
    items: any[],
    searchFirstName: string,
    searchLastName: string,
    searchEmail: string,
    searchMobile: string
  ) {
    if (items && items.length) {
      return items.filter((item) => {
        if (
          searchFirstName &&
          item.first_name
            .toLowerCase()
            .indexOf(searchFirstName.toLowerCase()) === -1
        ) {
          return false;
        }

        if (
          searchLastName &&
          item.last_name.toLowerCase().indexOf(searchLastName.toLowerCase()) ===
            -1
        ) {
          return false;
        }

        if (
          searchEmail &&
          item.emailid.toLowerCase().indexOf(searchEmail.toLowerCase()) === -1
        ) {
          return false;
        }

        if (
          searchMobile &&
          item.contact.toLowerCase().indexOf(searchMobile.toLowerCase()) === -1
        ) {
          return false;
        }

        return true;
      });
    } else {
      return items;
    }
  }
}

// Organization Filter
@Pipe({
  name: "rolesOrgFilter",
})
export class DataOrgFilterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter((it) => {
      return it.organisation_name.toLowerCase().includes(searchText);
    });
  }
}

//Filter Bus Management
@Pipe({
  name: "busManagementFilter",
})
export class BusManagementPipe implements PipeTransform {
  transform(
    items: any[],
    busSearchQ: string,
    registrationSearch: string,
    boardingSearch: string,
    droppingSearch: string
  ) {
    if (items && items.length) {
      return items.filter((item) => {
        if (
          busSearchQ &&
          item.busName.toLowerCase().indexOf(busSearchQ.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          registrationSearch &&
          item.bus_reg_no
            .toLowerCase()
            .indexOf(registrationSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          boardingSearch &&
          item.board_point
            .toLowerCase()
            .indexOf(boardingSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          droppingSearch &&
          item.drop_point
            .toLowerCase()
            .indexOf(droppingSearch.toLowerCase()) === -1
        ) {
          return false;
        }

        return true;
      });
    } else {
      return items;
    }
  }
}

//Boarding Dropping Point Filter
@Pipe({
  name: "boardDropFilter",
})
export class BoardDropPipe implements PipeTransform {
  transform(
    items: any[],
    busSearchQ: string,
    registrationSearch: string,
    terminal: string
  ) {
    if (items && items.length) {
      return items.filter((item) => {
        if (
          busSearchQ &&
          item.bus_id.busName
            .toLowerCase()
            .indexOf(busSearchQ.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          registrationSearch &&
          item.bus_id.bus_reg_no
            .toLowerCase()
            .indexOf(registrationSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          terminal &&
          item.address.toLowerCase().indexOf(terminal.toLowerCase()) === -1
        ) {
          return false;
        }

        return true;
      });
    } else {
      return items;
    }
  }
}

//TODO : BUS Tickets Filter
@Pipe({
  name: "busTicketsFilter",
})
export class BusTicketsFilterPipe implements PipeTransform {
  transform(
    items: any[],
    firstnameSearch: string,
    passengernameSearch: string,
    emailSearch: string,
    mobileSearch: string,
    ticketStatus: string
  ) {
    if (items && items.length) {
      return items.filter((item) => {
        if (
          firstnameSearch &&
          item.first_name
            .toLowerCase()
            .indexOf(firstnameSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          passengernameSearch &&
          item.ticket_details[0].name
            .toLowerCase()
            .indexOf(passengernameSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          emailSearch &&
          item.ticket_details[0].pEmail
            .toLowerCase()
            .indexOf(emailSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          mobileSearch &&
          item.ticket_details[0].pMobile
            .toLowerCase()
            .indexOf(mobileSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          ticketStatus &&
          item.ticket_status
            .toLowerCase()
            .indexOf(ticketStatus.toLowerCase()) === -1
        ) {
          return false;
        }

        return true;
      });
    } else {
      return items;
    }
  }
  // transform(items: any[], searchEmailText: string, searchFirstNameText: string, searchPassengerNameText: string, searchMobileText: string, searchTicketStatus: string, searchReference:String): any[] {

  //   if(!items) {
  //     return []
  //   }

  //   if(!searchEmailText || !searchFirstNameText || searchFirstNameText || searchPassengerNameText || searchMobileText || searchTicketStatus || searchReference) {
  //     return items
  //   }
  //    searchEmailText = searchEmailText.toLocaleLowerCase()
  //    searchFirstNameText = searchFirstNameText.toLocaleLowerCase()
  //    searchMobileText = searchMobileText.toLocaleLowerCase()
  //    searchReference = searchReference.toLocaleLowerCase()
  //    searchTicketStatus = searchTicketStatus.toLocaleLowerCase()
  //    searchPassengerNameText = searchPassengerNameText.toLocaleLowerCase()

  //   return items.filter(item=>{
  //     return item.toLocaleLowerCase().includes(searchEmailText) || item.toLocaleLowerCase().includes

  //   })
  // }
}

//TODO : Driver Duty assinged list fiilter
@Pipe({
  name: "driverDutiesList",
})
export class DriverDutyListFilterPipe implements PipeTransform {
  transform(
    items: any[],
    firstnameSearch: string,
    lastnameSearch: string,
    emailSearch: string,
    mobileSearch: string,
    orgSearch: string,
    assignBySearch: string,
    board_pointSearch: string,
    drop_pointSearch: string,
    is_assigned: string
  ) {
    if (items && items.length) {
      return items.filter((item) => {
        if (
          firstnameSearch &&
          item.driver_id.fullName
            .toLowerCase()
            .indexOf(firstnameSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          lastnameSearch &&
          item.driver_id.lastName
            .toLowerCase()
            .indexOf(lastnameSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          emailSearch &&
          item.driver_id.emailAddress
            .toLowerCase()
            .indexOf(emailSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          mobileSearch &&
          item.driver_id.mobileno
            .toLowerCase()
            .indexOf(mobileSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          orgSearch &&
          item.organisation_ids.organisation_name
            .toLowerCase()
            .indexOf(orgSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          assignBySearch &&
          item.duty_assigned_by.fullName
            .toLowerCase()
            .indexOf(assignBySearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          board_pointSearch &&
          item.route_id.bus_boadingpoint
            .toLowerCase()
            .indexOf(board_pointSearch.toLowerCase()) === -1
        ) {
          return false;
        }
        if (
          drop_pointSearch &&
          item.route_id.bus_dropingpoint
            .toLowerCase()
            .indexOf(drop_pointSearch.toLowerCase()) === -1
        ) {
          return false;
        }

        return true;
      });
    } else {
      return items;
    }
  }
}

// Todo : This is for sidemenu filter
@Pipe({ name: "filterByRole" })
export class FilterByRolePipe implements PipeTransform {
  transform(roleList: any, role_name: string): any[] {
    if (roleList) {
      // var gg = roleList.includes((listing: any) => listing === role_name);
      var gg = roleList.includes(role_name);
      return gg;
    }
  }
}

@Pipe({ name: "datecomparepipe" })
export class DateComparePipe implements PipeTransform {
  // transform(size: number): string {
  //   return (size / (1024 * 1024)).toFixed(2) + 'MB';
  // }

  transform(value: any, currentDate?: any, journeyDate?: any): any {
    var _currentDate = new Date(currentDate);
    var _journeyDate = new Date(journeyDate);
    if (_journeyDate >= _currentDate) {
      return true;
    } else {
      return false;
    }
  }
}

@Pipe({ name: "convertFrom24To12Format" })
export class TimeFormat implements PipeTransform {
  transform(time: any): any {
    let hour = time.split(":")[0];
    let min = time.split(":")[1];
    let part = hour > 12 ? "PM" : "AM";
    min = (min + "").length == 1 ? `0${min}` : min;
    hour = hour > 12 ? hour - 12 : hour;
    hour = (hour + "").length == 1 ? `0${hour}` : hour;
    return `${hour}:${min} ${part}`;
  }
}
