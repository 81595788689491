import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { UserService } from "../../services/index";
import { ToastrManager } from "ng6-toastr-notifications";
import { ErrorService } from "src/app/services/error.service";
import { Select2OptionData } from "ng2-select2";
import { Router } from "@angular/router";
import {
  NgbModal,
  NgbActiveModal,
  ModalDismissReasons,
  NgbModalRef
} from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-modal-route",
  templateUrl: "./modal-route.component.html",
  styleUrls: ["./modal-route.component.css"]
})
export class ModalRouteComponent implements OnInit {
  @Output() notify = new EventEmitter<any>();

  model: any = {};
  isView_Edit: any;
  route_details: any;
  busesListData: any;
  selectedBusDetails: any = {};
  _allBuses: Array<Select2OptionData>;
  selected: string;
  //time
  boarding_time: any = {};
  droping_time: any = {};
  meridian = true;
  toggleMeridian() {
    this.meridian = !this.meridian;
  }
  //Final Update data value variable
  update_route_data: any;

  constructor(
    private userService: UserService,
    public toastr: ToastrManager,
    private errorService: ErrorService,
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.isView_Edit = this.model.action;
    this.getAllbuses();
    let board_time = this.model.item.bus_boadingtime.split(":");
    let drop_time = this.model.item.bus_dropingtime.split(":");
    this.boarding_time = {
      hour: board_time[0],
      minute: board_time[1]
    };
    this.droping_time = {
      hour: drop_time[0],
      minute: drop_time[1]
    };
    this.route_details = {
      id: this.model.item.id,
      busName: this.model.item.bus_id.busName,
      bus_id: this.model.item.bus_id.id,
      from_state: this.model.item.from_state,
      to_state: this.model.item.to_state,
      bus_boadingpoint: this.model.item.bus_boadingpoint,
      bus_boadingtime: this.boarding_time,
      bus_dropingpoint: this.model.item.bus_dropingpoint,
      bus_dropingtime: this.droping_time,
      price: this.model.item.bus_fare,
      createdAt: this.model.item.createdAt,
      updatedAt: this.model.item.updatedAt
    };
    
  }

  // GET all buses
  getAllbuses() {
    this.userService.getAllBuses().subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.busesListData = Object(t).data;
        var objBusesListArray = [];

        objBusesListArray.push({
          id: this.model.item.bus_id.id,
          text: this.model.item.bus_id.busName
        });

        this.busesListData.forEach(t => {
          var _childrens = [];
          t.buses_id.forEach(bus => {
            _childrens.push({ id: bus.id, text: bus.busName });
          });
          objBusesListArray.push({
            id: t.id,
            text: t.organisation_name,
            children: _childrens
          });
        });

        this._allBuses = objBusesListArray;
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }
  public changed(e: any): void {
    this.selected = e.value;
    
    var states = {};
    this.busesListData.forEach(b => {
      b.buses_id.filter(bus => {
        if (e.value == bus.id) {
          states = { bus: bus, organisation_ids: b.id };
        }
      });
    });
    this.selectedBusDetails = states;
  }

  public onSubmit() {
    this.update_route_data = {
      id: this.route_details.id,
      bus_id: this.selected,
      from_state: this.model.item.from_state,
      to_state: this.model.item.to_state,
      organisation_ids: this.selectedBusDetails.organisation_ids,
      bus_fare: this.route_details.price,
      bus_boadingpoint: this.route_details.bus_boadingpoint,
      bus_boadingtime:
        this.boarding_time.hour + ":" + this.boarding_time.minute,
      bus_dropingpoint: this.route_details.bus_dropingpoint,
      bus_dropingtime: this.droping_time.hour + ":" + this.droping_time.minute,
      bus_status: true
    };
    this.userService.updateRoute(this.update_route_data).subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.toastr.successToastr("Succesfully update route  !");
        this.activeModal.dismiss();
        this.notify.emit(this.update_route_data);
        // this.router.navigate(["route/view"]);
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }
}
