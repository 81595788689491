import { Injectable } from "@angular/core";
import { Headers, RequestOptions, Response } from "@angular/http";
import { Router } from "@angular/router";
import { of } from "rxjs";

@Injectable()
export class SessionService {
  constructor(private myRouter: Router) {
    this.handleError = this.handleError.bind(this);
    this.extractData = this.extractData.bind(this);
  }

  newSessionForUser(sessionData: SessionData) {
    // localStorage.setItem("currentUser", JSON.stringify(sessionData));
    localStorage.setItem("currentUser", JSON.stringify({
        user: sessionData,
        token: sessionData.token
      }));
  }

  deleteSession() {
    localStorage.removeItem("currentUser");
  }

  get isUserLoggedIn(): boolean {
    !!this.currentUser || this.myRouter.navigate(["/login"]);
    return true;
  }

  get authToken(): RequestOptions {
    if (this.currentUser && this.currentUser.token) {
      const headers = new Headers({
        Authorization: "Bearer " + this.currentUser.token
      });
      return new RequestOptions({ headers });
    }
    return new RequestOptions({});
  }

  get getUserName(): string {
    if (this.currentUser && this.currentUser.userName) {
      return this.currentUser.userName;
    }
    return "";
  }

  get getFullName(): string {
    if (this.currentUser && this.currentUser.fullName) {
      return this.currentUser.fullName;
    }
    return "";
  }

  private get currentUser(): SessionData {
    return JSON.parse(localStorage.getItem("currentUser")) as SessionData;
  }

  extractData(res: Response) {
    return res || {};
  }

  handleError(error: Response | any) {
    let errMsg: string;

    if (error instanceof Response) {
      // TODO: Create unathorized page
      if (error.status === 401 || error.status === 403) {
        this.myRouter.navigate(["/login"]);
        localStorage.removeItem("currentUser");
        errMsg = `${error.status} - ${error.statusText || ""}`;
        // this.snackBar.open("Session Expired or UnAuthorized", null, { duration: 5000 });
      } else {
        const body = error.json() || "";
        const err = body.error || JSON.stringify(body);
        errMsg = `${error.status} - ${error.statusText || ""} ${err}`;
        // this.snackBar.open("An error occurred, please contact your Administrator.", null, { duration: 5000 });
      }
    } else {
      errMsg = error.message ? error.message : error.toString();
    }
    console.error(errMsg);
    return of(errMsg);
  }
}

export class SessionData {
  userName: string;
  token: string;
  // public modules: ModuleDto[];
  // public userId: number;
  fullName: string;
  emailAddress: string;
  role: string;
  avatar: string;
  roles: string;
  createdAt: string;
  updatedAt: string;
  isSuperAdmin: boolean;
  organisation_ids: any;
}
