import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { ToastrManager } from "ng6-toastr-notifications";
import { ErrorService } from "src/app/services/error.service";
import { Select2OptionData } from "ng2-select2";
import { Router } from "@angular/router";
import {
  NgbModal,
  NgbActiveModal,
  ModalDismissReasons,
  NgbModalRef
} from "@ng-bootstrap/ng-bootstrap";
import { UserService } from "src/app/services/jwt/user.service";
import * as moment from "moment";
import * as _ from "lodash";
@Component({
  selector: "app-customer-modal",
  templateUrl: "./customer-modal.component.html",
  styleUrls: ["./customer-modal.component.css"]
})
export class CustomerModalComponent implements OnInit {
  model: any = {};
  isView_Edit: any;
  bookedticket: any = [];
  public todaysdate = moment().format("YYYY-MM-DD");
  boarding_pointListData:any;

  constructor(
    private userService: UserService,
    public toastr: ToastrManager,
    private errorService: ErrorService,
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.isView_Edit = this.model.action;
    var data = { emailAddress: this.model.item.emailAddress };
    this.getTicketByEmailId(data);
  }

  getTicketByEmailId(data) {
    this.userService.getMyTicket(data).subscribe(t => {
      if (!!t && t.constructor === Object) {
        if (Object(t).data.constructor === Array) {
          this.bookedticket = Object(t).data;
        }
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }

}
