import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/index";
import { ToastrManager } from "ng6-toastr-notifications";
import { ErrorService } from "src/app/services/error.service";
import { Select2Module } from "ng2-select2";
import { Router } from "@angular/router";
import {
  NgbModal,
  NgbActiveModal,
  ModalDismissReasons,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-modal-driver",
  templateUrl: "./modal-driver.component.html",
  styleUrls: ["./modal-driver.component.css"],
})
export class ModalDriverComponent implements OnInit {
  model: any = {};
  isView_Edit: any;
  driverDetails: any; // get all
  selectedNav: boolean;
  driver_update_data: any;
  constructor(
    private userService: UserService,
    public toastr: ToastrManager,
    private errorService: ErrorService,
    public activeModal: NgbActiveModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.isView_Edit = this.model.action;

    this.driverDetails = {
      id: this.model.item.id,
      name: this.model.item.fullName,
      firstName: this.model.item.firstName,
      lastName: this.model.item.lastName,
      mobile_number: this.model.item.mobileno,
      email: this.model.item.emailAddress,
    };

    this.getDriverDetailsById(this.driverDetails.id);
  }

  getDriverDetailsById(id) {
    this.userService.getdriverDetails(id).subscribe((t) => {
      if (!!t && t.constructor === Object) {
        var _data = t["data"][0];
        this.driverDetails.address = _data.address ? _data.address : "";
        this.driverDetails.locality = _data.locality ? _data.locality : "";
        this.driverDetails.city = _data.city ? _data.city : "";
        this.driverDetails.state = _data.state ? _data.state : "";
        this.driverDetails.country = _data.country ? _data.country : "";
        this.driverDetails.pincode = _data.pincode ? _data.pincode : "";
        this.driverDetails.licence_no = _data.licence_no
          ? _data.licence_no
          : "";
        this.driverDetails.is_ac_available = _data.is_ac_available ? 1 : 0;
        this.driverDetails.bank_ac_no = _data.bank_ac_no
          ? _data.bank_ac_no
          : "";
        this.driverDetails.bank_name = _data.bank_name ? _data.bank_name : "";
        this.driverDetails.IFSC_code = _data.IFSC_code ? _data.IFSC_code : "";
        this.driverDetails.bank_location = _data.bank_location
          ? _data.bank_location
          : "";
        this.driverDetails.is_activated = _data.is_activated
          ? _data.is_activated
          : "";
        this.driverDetails.duty_status = _data.duty_status
          ? _data.duty_status
          : "";
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }

  isAccount(yes) {
    this.driverDetails.is_ac_available = JSON.parse(yes);
  }
  onSubmit() {
    this.driver_update_data = {
      driver_id: this.driverDetails.id,
      firstName: this.driverDetails.firstName,
      lastName: this.driverDetails.lastName,
      mobileno: this.driverDetails.mobile_number,
      address: this.driverDetails.address,
      locality: this.driverDetails.locality,
      city: this.driverDetails.city,
      state: this.driverDetails.state,
      country: this.driverDetails.country,
      pincode: this.driverDetails.pincode,
      licence_no: this.driverDetails.licence_no,
      is_ac_available: this.driverDetails.is_ac_available,
      bank_ac_no: this.driverDetails.bank_ac_no,
      bank_name: this.driverDetails.bank_name,
      IFSC_code: this.driverDetails.IFSC_code,
      bank_location: this.driverDetails.bank_location,
      is_activated: this.driverDetails.is_activated,
      duty_status: this.driverDetails.duty_status,
    };

    this.userService
      .updateDriverDetails(this.driver_update_data)
      .subscribe((t) => {
        if (!!t && t.constructor === Object) {
          this.toastr.successToastr("Succesfully update bus drivers  !");
          this.activeModal.dismiss();
          this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
          };
        } else {
          if (this.errorService.errorMessage) {
            var msg = JSON.parse(this.errorService.errorMessage);
            this.toastr.errorToastr(msg.body);
          }
        }
      });
  }
}
