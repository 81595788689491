import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { UserService } from "../../services/index";
import { ToastrManager } from "ng6-toastr-notifications";
import { ErrorService } from "src/app/services/error.service";
import { Select2Module } from "ng2-select2";
import { Router } from "@angular/router";
import {
  NgbModal,
  NgbActiveModal,
  ModalDismissReasons,
  NgbModalRef
} from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-modal-bus",
  templateUrl: "./modal-bus.component.html",
  styleUrls: ["./modal-bus.component.css"]
})
export class ModalBusComponent implements OnInit {
  @Output() notify = new EventEmitter<any>();

  model: any = {};
  _amtids: any;
  bus_list: any = {};
  isView_Edit: any;
  boarding_time: any = {};
  droping_time: any = {};
  meridian = true;
  organizationListData: any = [];
  editBusListData: any = {};
  busTypesListData: any = [];
  amenitiesListData = {};
  final_bus_update_data: any = {};
  edit_board_time: any;
  edit_drop_time: any;

  busTerminals: any = [];
  toggleMeridian() {
    this.meridian = !this.meridian;
  }
  public options: Select2Options;
  public value: string[];
  public amenities_ids: string;
  constructor(
    private userService: UserService,
    public toastr: ToastrManager,
    private errorService: ErrorService,
    public activeModal: NgbActiveModal,
    private router: Router
  ) {}
  ngOnInit() {
    this.isView_Edit = this.model.action;
    this.getAllOrganization();
    this.getAllBusesTypes();
    this.getAllAmenities();
    if(this.isView_Edit==='stations'){
      this.getAllStationByBusId(this.model.item);
    }
    if(this.isView_Edit==='view' || this.isView_Edit==='edit'){
      this.getEditBusById(this.model.item);
    }
    this.options = { multiple: true };
  }

  changed(data: { value: string[] }) {
    this.amenities_ids = data.value.join(",");
  }
  getAllOrganization() {
    this.userService.get_all_organization().subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.organizationListData = Object(t).data;
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }
  getAllBusesTypes() {
    this.userService.getAllBusTypes().subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.busTypesListData = Object(t).data;
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }
  getAllAmenities() {
    this.userService.getAllAmenities().subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.successAllAmenities(t);
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }
  successAllAmenities(res) {
    // this.amenitiesListData = res.data;
    const newArrayOfAmenitiesObj = res.data.map(
      ({ id: id, amenities: text }) => ({
        id,
        text
      })
    );
    this.amenitiesListData = newArrayOfAmenitiesObj;
  }

  getEditBusById(id) {
    this.userService.editBusbyId(id).subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.editBusListData = Object(t).data["0"];
        var selectedValues =[];
        this.editBusListData.amenities_id.forEach(m=>{
          selectedValues.push(m.id);
        })
        this.value = selectedValues;
        this.amenities_ids = this.value.join(",");
        this._amtids = this.editBusListData.amenities_id;

        this.bus_list = {
          organisation_id: this.editBusListData.organisation_ids[0].id,
          organisation_name: this.editBusListData.organisation_ids[0]
            .organisation_name,
          id: this.editBusListData.id,
          busName: this.editBusListData.busName,
          bus_reg_no: this.editBusListData.bus_reg_no,
          // max_seats: this.editBusListData.max_seats,
          bustype_id: this.editBusListData.bustype_id,
          amenities_id: this._amtids,
          status: 2,
          bus_status: true,
          created_by : this.editBusListData.created_by
        };
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }
  public onSubmit() {
    this.final_bus_update_data = {
      organisation_ids: this.bus_list.organisation_id,
      id: this.bus_list.id,
      busName: this.bus_list.busName,
      bus_reg_no: this.bus_list.bus_reg_no,
      bustype_id: this.bus_list.bustype_id.id,
      amenities_id: this.amenities_ids,
      status: 2,
      bus_status: true
    };
    this.userService.updateBus(this.final_bus_update_data,this.bus_list.created_by).subscribe(t => {
      if (!!t && t.constructor === Object) {
        if(t['data'].error){
          this.toastr.errorToastr(t['message']);
        }
        else {
          this.toastr.successToastr("Succesfully update bus bus  !");
          this.activeModal.dismiss();
          this.notify.emit(this.final_bus_update_data)
        }
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }

  getAllStationByBusId(busId) {
    this.userService.getAllTerminalsByBusId(busId).subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.busTerminals = Object(t).data;
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }

  goToAddRoutesPage(){
    this.activeModal.dismiss();
    this.router.navigate(['route/add']);
  }
}
