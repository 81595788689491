import { Component, OnInit, EventEmitter, Output } from "@angular/core";

import { AuthService, UserService } from "../../services/index"; // services

import { Router } from "@angular/router";
import { environment } from "src/environments/environment.prod";

import {
  NgbModal,
  ModalDismissReasons,
  NgbPanelChangeEvent,
  NgbCarouselConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { PerfectScrollbarConfigInterface } from "ngx-perfect-scrollbar";
declare var $: any;

@Component({
  selector: "app-navigation",
  templateUrl: "./navigation.component.html",
})
export class NavigationComponent implements OnInit {
  @Output()
  toggleSidebar = new EventEmitter<void>();

  public config: PerfectScrollbarConfigInterface = {};

  public showSearch = false;
  public fullName: any;
  public loginUser: any;
  public navName: string = null;
  public avatarUrl: string = null;
  private API_URL = environment.API_URL;
  defaultImageUrl: string;
  currentUser: any;

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private authservice: AuthService,
    private userService: UserService
  ) {}

  // This is for Notifications
  notifications: Object[] = [
    {
      btn: "btn-danger",
      icon: "ti-link",
      title: "Luanch Admin",
      subject: "Just see the my new admin!",
      time: "9:30 AM",
    },
    {
      btn: "btn-success",
      icon: "ti-calendar",
      title: "Event today",
      subject: "Just a reminder that you have event",
      time: "9:10 AM",
    },
    {
      btn: "btn-info",
      icon: "ti-settings",
      title: "Settings",
      subject: "You can customize this template as you want",
      time: "9:08 AM",
    },
    {
      btn: "btn-primary",
      icon: "ti-user",
      title: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:00 AM",
    },
  ];

  // This is for Mymessages
  mymessages: Object[] = [
    {
      useravatar: "assets/images/users/1.jpg",
      status: "online",
      from: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:30 AM",
    },
    {
      useravatar: "assets/images/users/2.jpg",
      status: "busy",
      from: "Sonu Nigam",
      subject: "I have sung a song! See you at",
      time: "9:10 AM",
    },
    {
      useravatar: "assets/images/users/2.jpg",
      status: "away",
      from: "Arijit Sinh",
      subject: "I am a singer!",
      time: "9:08 AM",
    },
    {
      useravatar: "assets/images/users/4.jpg",
      status: "offline",
      from: "Pavan kumar",
      subject: "Just see the my admin!",
      time: "9:00 AM",
    },
  ];

  ngOnInit() {
    this.currentUser = this.userService.newSessionForUser();
    this.defaultImageUrl = environment.IMAGE_URL;

    if (this.currentUser) {
      this.loginUser = this.currentUser;
      if (!this.loginUser.user.isSuperAdmin) {
        this.navName =
          this.loginUser.user.organisation_ids[0].organisation_name;
      } else {
        this.navName = this.loginUser.user.fullName;
      }
    } else {
      this.loginUser = null;
    }

    this.avatarUrl =
      this.loginUser.user.organisation_logo || this.defaultImageUrl;
  }

  do_logout(): void {
    this.userService.log_out();
    this.router.navigate(["/authentication/login"]);
  }
}
