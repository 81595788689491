import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/index";
import { ToastrManager } from "ng6-toastr-notifications";
import { ErrorService } from "src/app/services/error.service";
import { Select2Module } from "ng2-select2";
import { Router } from "@angular/router";
import {
  NgbModal,
  NgbActiveModal,
  ModalDismissReasons,
  NgbModalRef
} from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-modal-booking-details',
  templateUrl: './modal-booking-details.component.html',
  styleUrls: ['./modal-booking-details.component.css']
})
export class ModalBookingDetailsComponent implements OnInit {
  model: any = {};
  constructor(
    public activeModal: NgbActiveModal,
   ) { }

  ngOnInit() {
    
  }

}
