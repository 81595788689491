import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { ToastrManager } from "ng6-toastr-notifications";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { BaseUrl } from "./baseUrl";
import { SessionService, SessionData } from "./session/session.service";

@Injectable({
  providedIn: "root"
})
export class AuthService {
  public token: string;

  constructor(
    private http: HttpClient,
    public toastr: ToastrManager,
    public sessionService: SessionService
  ) {
    var currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.token = currentUser && currentUser.token;
    
  }

  // for sigin*********************************************************************************************************
  signin(user) {
    const jsonUser = JSON.stringify(user);
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/auth/login/", jsonUser)
      .map(this._loginSuccess)
      .catch(this._loginErrorHandling);
  }
  _loginSuccess(res) {
    let response = res.data;
    let token = response && response.token;
    if (token) {
      // set token property
      this.token = token;
      // store username and jwt token in local storage to keep user logged in between page refreshes
      localStorage.setItem(
        "currentUser",
        JSON.stringify({ user: res.data.user, token: token })
      );

      // return true to indicate successful login
      return { status: true };
    } else {
      // return false to indicate failed login
      var returnData = {
        status: false,
        message: res
      };
      return returnData;
    }
  }
  _loginErrorHandling(res) {
    
    return Observable.throw(res);
  }

  // Register submit ***********************************************************************************************
  register(user) {
    // const jsonUser = JSON.stringify(user);
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/auth/signup", user)
      .map(this._success)
      .catch(this._errorHandling);
  }
  _success(res) {
    return res;
  }
  _errorHandling(res) {
    
    return Observable.throw(res);
  }

  // logout ***************************************************************************************************************
  log_out() {
    // clear token remove user from local storage to log user out
    this.token = null;
    localStorage.removeItem("currentUser");
    this.toastr.successToastr("Succesfully Logout! ", "Success!");
  }

  // Forget Password ***************************************************************************************************************
  forgetPassowrd(user) {
    const jsonUser = JSON.stringify(user);
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/auth/forgotPassword", jsonUser)
      .map(this._forgetSuccess)
      .catch(this._errorHandling);
  }
  _forgetSuccess(_forgetSuccess: any): any {
    return _forgetSuccess;
  }

  // Reset Password ***************************************************************************************************************
  resetPassowrd(user) {
    const jsonUser = JSON.stringify(user);
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/auth/resetPassword", jsonUser)
      .map(this._resetSuccess)
      .catch(this._errorHandling);
  }
  _resetSuccess(_forgetSuccess: any): any {
    return _forgetSuccess;
  }


}
