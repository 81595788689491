import { Component, OnInit } from '@angular/core';
import {
  NgbModal, NgbActiveModal,  ModalDismissReasons,  NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: "app-routes",
  templateUrl: "./routes.component.html",
  styleUrls: ["./routes.component.css"]
})
export class RoutesComponent implements OnInit {
  model: any = [];
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
  }
}
