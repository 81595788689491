import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import "rxjs/add/operator/map";
import "rxjs/add/operator/catch";
import "rxjs/add/observable/throw";
import { BaseUrl } from "../baseUrl";
import { Router } from "@angular/router";
// import { AuthService } from '../../services/index'; // services
import {
  HttpErrorHandler,
  HandleError,
} from "../../services/http-error-handler.service"; // services
import { catchError } from "rxjs/operators";
import { ToastrManager } from "ng6-toastr-notifications";
import { useAnimation } from "@angular/animations";
import { ExportCSV } from "src/app/export/export";
import { startOfDay } from "date-fns";
@Injectable({
  providedIn: "root",
})
export class UserService {
  private handleError: HandleError;
  public currentUser: any = {};
  private exportCsv: ExportCSV;

  constructor(
    private http: HttpClient,
    private router: Router,
    public toastr: ToastrManager,
    // private authservice: AuthService,
    httpErrorHandler: HttpErrorHandler
  ) {
    this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.handleError = httpErrorHandler.createHandleError("UserService");
  }
  newSessionForUser() {
    return JSON.parse(localStorage.getItem("currentUser"));
  }

  getSessionOrgId() {
    var result = JSON.parse(localStorage.getItem("currentUser"));
    if (result.user.organisation_ids.length > 0) {
      var org = result.user.organisation_ids[0].id;
      return org;
    }
  }

  log_out() {
    // clear token remove user from local storage to log user out
    this.currentUser = null;
    localStorage.removeItem("currentUser");
    this.toastr.successToastr("Succesfully Logout! ", "Success!");
  }
  //TODO : get all Organisation name
  get_all_organization() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "user/v1/dashboard/organisation", this.jwt())
      .pipe(catchError(this.handleError("get_all_organization", [])));
  }

  //TODO: User method api
  addUser(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/auth/createmanagementuser",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("addUser", [])));
  }

  // TODO : All User ADDED method API
  getAllUsers() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "user/v1/dashboard/userslist", this.jwt())
      .pipe(catchError(this.handleError("getAllUsers", [])));
  }
  // TODO : All Bus Owners Users
  getAllBusOwners() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "users/get_all_bus_owner", this.jwt())
      .pipe(catchError(this.handleError("getAllBusOwners", [])));
  }
  // TODO : All Bus Managers Users
  getAllBusManagers() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "users/get_all_bus_manager", this.jwt())
      .pipe(catchError(this.handleError("getAllBusManagers", [])));
  }
  //  update users
  updateUsers(userObj) {
    return this.http
      .post(BaseUrl.API_ENDPOINT + "users/update", userObj, this.jwt())
      .pipe(catchError(this.handleError("getAllBusManagers", [])));
  }

  updateOrganisationIdentification(image: any) {
    const formData = new FormData();
    formData.append("organisation_identification", image);
    return this.http
      .post(
        `${BaseUrl.API_ENDPOINT}users/uploadOrganisationIdentification`,
        formData,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getAllBusManagers", [])));
  }

  updateOrganisationLogo(image: any) {
    const formData = new FormData();
    formData.append("organisation_logo", image);
    return this.http
      .post(
        `${BaseUrl.API_ENDPOINT}users/uploadOrganisationLogo`,
        formData,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getAllBusManagers", [])));
  }
  // TODO : All Bus Drivers Users
  getAllBusDrivers() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "users/get_all_bus_driver", this.jwt())
      .pipe(catchError(this.handleError("getAllBusDrivers", [])));
  }
  // TODO : All Agent
  addAgent(data) {
    data["roles"] = "isAgent";
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/auth/agent", data, this.jwt())
      .pipe(catchError(this.handleError("addUser", [])));
  }

  getAllAgent() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "user/v1/auth/all_agent", this.jwt())
      .pipe(catchError(this.handleError("getAllAgent", [])));
  }

  updateCustomer(customer) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/customer/update",
        customer,
        this.jwt()
      )
      .pipe(catchError(this.handleError("updateCustomer", [])));
  }
  //TODO Add Bus Type [GET, EDIT,POST,UPDATE, DELETE]
  addBusType(data) {
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/bus/addbustype", data, this.jwt())
      .pipe(catchError(this.handleError("addBusType", [])));
  }
  getAllBusTypes() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "user/v1/bus/getallbustype", this.jwt())
      .pipe(catchError(this.handleError("getAllBusTypes", [])));
  }
  updateBusType(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/bus/updatebustype",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("updateBusType", [])));
  }
  deleteBusType(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/bus/deletebustype",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("deleteBusType", [])));
  }

  //TODO : AMENITIES  [EDIT,POST,GET,UPDATE]
  postAmenities(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/bus/busamenitiescreate",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("postAmenities", [])));
  }
  getAllAmenities() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "user/v1/bus/getallbusamenities", this.jwt())
      .pipe(catchError(this.handleError("getAllAmenities", [])));
  }
  updateAmenities(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/bus/updatebusamenitiesbyid",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("updateAmenities", [])));
  }
  deleteAmenities(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/bus/deletebusamenities",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("deleteAmenities", [])));
  }

  //TODO: BUS [GET,POST,EDIT,UPDATE]
  postCreateBus(data) {
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/bus/busCreate", data, this.jwt())
      .pipe(catchError(this.handleError("postBusBoardingPoint", [])));
  }
  getAllBuses() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "user/v1/bus/getallbuses", this.jwt())
      .pipe(catchError(this.handleError("getAllBuses", [])));
  }
  editBusbyId(id) {
    return this.http
      .get(
        BaseUrl.API_ENDPOINT + "user/v1/bus/editbusbyid?id=" + id,
        this.jwt()
      )
      .pipe(catchError(this.handleError("editBusbyId", [])));
  }
  updateBus(data, busCreatedBy) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT +
          "user/v1/bus/updatebusbyid?createdby=" +
          busCreatedBy,
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("updateBus", [])));
  }
  deleteBus(id) {
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/bus/deletebus", id, this.jwt())
      .pipe(catchError(this.handleError("deleteBus", [])));
  }

  // TODO: BOARDING POINT [ GET,POST,UPDATE,EDIT ]

  // TODO: DROPING POINT [GET,POST,UPDATE,EDIT]

  // ROUTE  [POST, VIEW, EDIT, UPDATE]
  postRoute(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/bus/busroutecreate",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("postRoute", [])));
  }
  getAllRoute() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "user/v1/bus/getallbusroutes", this.jwt())
      .pipe(catchError(this.handleError("getAllBuses", [])));
  }
  getRouteByBusId(id) {
    return this.http
      .get(
        BaseUrl.API_ENDPOINT + "user/v1/bus/getbusroutebybusid/" + id,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getAllBuses", [])));
  }
  updateRoute(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/bus/updatebusroutebyid",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("updateRoute", [])));
  }
  deleteRoute(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/bus/deletebusroute",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("deleteRoute", [])));
  }

  // BUS SEAT LAYOUT  [POST, VIEW, EDIT, UPDATE]
  getAllBusSeatLayout() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "user/v1/bus/getbuslayout", this.jwt())
      .pipe(catchError(this.handleError("getAllBusSeatLayout", [])));
  }
  postBusSeatLayout(data) {
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/bus/buslayout", data, this.jwt())
      .pipe(catchError(this.handleError("postBusSeatLayout", [])));
  }

  UpdateBusSeatLayout(data) {
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/bus/layoutupdate", data, this.jwt())
      .pipe(catchError(this.handleError("postBusSeatLayout", [])));
  }

  updateBusRoute(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/bus/updatebusroutebyid",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("postBusSeatLayout", [])));
  }

  //CUSTOMER
  getAllCustomer(sDate, eDate) {
    return this.http
      .get(
        BaseUrl.API_ENDPOINT + `Home/v1/customer/getallcustomers`,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getAllCustomer", [])));
  }

  getPassenger(sDate, eDate) {
    return this.http
      .get(
        BaseUrl.API_ENDPOINT +
          `user/v1/passenger/details/?sDate=${sDate}&eDate=${eDate}`,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getAllCustomer", [])));
  }

  //test frontend boarding deboarding api
  getBoardingByrouteid(id) {
    return this.http.get(
      BaseUrl.API_ENDPOINT + "Home/v1/bus/getboardbyroute?route_id=" + id
    );
  }
  getDeboardinggByrouteid(id) {
    return this.http.get(
      BaseUrl.API_ENDPOINT + "Home/v1/bus/getdropbyroute?route_id=" + id
    );
  }

  // TODO  :  DRIVER API

  //driver list
  getdriverDetails(id) {
    return this.http
      .get(
        BaseUrl.API_ENDPOINT +
          "user/v1/dashboard/getdriverdetails?driver_id=" +
          id,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getdriverDetails", [])));
  }
  updateDriverDetails(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/dashboard/updatedriverdetails",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("updateDriverDetails", [])));
  }
  assigndutyToDriver(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/dashboard/assigndriverduty",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("postBusBoardingPoint", [])));
  }
  getdriverRoutesAssigned(id) {
    return this.http
      .get(
        BaseUrl.API_ENDPOINT + "user/v1/driver/getdriverdutybyid/" + id,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getdriverDetails", [])));
  }
  assignedDutiesListDrivers(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/dashboard/get_driver_duty",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("updateDriverDetails", [])));
  }

  cancelTicket(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/bus/adminCancelTicket",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("ticketStatus", [])));
  }

  updateTicketStatus(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/dashboard/ticketstatus",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("ticketStatus", [])));
  }

  // to cancel ticket on paystack
  createRefund(data) {
    return this.http
      .post(
        `${BaseUrl.API_ENDPOINT}user/v1/bus/createAdminRefund`,
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("createRefund", [])));
  }

  // TODO booking details get
  getbookingDetails(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/dashboard/bookedseats",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getbookingDetails", [])));
  }
  // update trip detail when click reached button
  update_my_duty(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/driver/update_my_duty",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("isdriverReachedDetails", [])));
  }

  // end trip update data
  EndMyTrip(data) {
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/driver/endmytrip", data, this.jwt())
      .pipe(catchError(this.handleError("endTripError", [])));
  }

  // update data fetch and compare
  getmyupdatedduty(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/driver/getmyupdatedduty",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getmyupdatedduty", [])));
  }
  //updatecustomerstatus
  updatecustomerstatus(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/driver/updatecustomerstatus",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("updatecustomerstatus", [])));
  }
  //get customer trip travel status get api
  gettodaysticket(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "home/v1/customer/gettodaysticket",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("gettodaysticket", [])));
  }

  //end driver duty trip
  endmyDriverTrip(data) {
    return this.http
      .post(BaseUrl.API_ENDPOINT + "user/v1/driver/endmytrip", data, this.jwt())
      .pipe(catchError(this.handleError("endmyDriverTrip", [])));
  }

  // admin show driver duty status
  getdriverdutylist(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/driver/getdriverdutylist",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getdriverdutylist", [])));
  }
  // admin show driver duty status
  getdriverdutylistbyid(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/driver/getdriverdutylistbyid",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getdriverdutylistbyid", [])));
  }

  // TODO Dashboard API

  // GET TOTAL PAYMENT details
  getallPaymentData() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "Home/v1/user/gettotalpayments")
      .pipe(catchError(this.handleError("getallPaymentData", [])));
  }

  // Dashboard Report
  getallReportByOrgId(id = null) {
    if (this.currentUser.user.roles != "isSuperAdmin") {
      return this.http
        .post(BaseUrl.API_ENDPOINT + "user/v1/dashboard/dailyreport", id)
        .pipe(catchError(this.handleError("getallRerportData", [])));
    } else {
      return this.http
        .post(BaseUrl.API_ENDPOINT + "user/v1/dashboard/dailyreport", "")
        .pipe(catchError(this.handleError("getallRerportData", [])));
    }
  }

  // Get Passenger Details
  getMyTicket(data) {
    return this.http.post(
      BaseUrl.API_ENDPOINT + "home/v1/customer/getmyticket",
      data
    );
  }

  getTicketHistry(agentid) {
    return this.http.get(
      BaseUrl.API_ENDPOINT + "user/v1/ticket/bookinghistory?agentid=" + agentid,
      this.jwt()
    );
  }

  UpdateComissionStatus(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/ticket/comission/status",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("status", [])));
  }

  //GET ALL STATIONS WRT BUS ID
  getAllTerminalsByBusId(bus_id) {
    return this.http
      .get(
        BaseUrl.API_ENDPOINT +
          "home/v1/dashboard/get_terminals_by_busid/" +
          bus_id,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getAllBoardingStationsByBusId", [])));
  }

  getDaysData(data) {
    return this.http
      .post(BaseUrl.API_ENDPOINT + "charts/users", data, this.jwt())
      .pipe(catchError(this.handleError("getDaysData", [])));
  }
  getBookingssData(data) {
    return this.http
      .post(BaseUrl.API_ENDPOINT + "charts/trips", data, this.jwt())
      .pipe(catchError(this.handleError("getDaysData", [])));
  }
  changedPassowrd(data) {
    return this.http
      .post(
        BaseUrl.API_ENDPOINT + "user/v1/auth/changePassword",
        data,
        this.jwt()
      )
      .pipe(catchError(this.handleError("getBookingssData", [])));
  }

  getExportTicketDetails() {
    return this.http
      .get(BaseUrl.API_ENDPOINT + "user/v1/auth/agentexport", this.jwt())
      .pipe(catchError(this.handleError("ticketDetails", [])));
  }

  downloadCsv(filename, data) {
    this.exportCsv = new ExportCSV(data);
    return this.exportCsv.download(filename);
  }

  // driver today report for dashboard

  todayReport(driverId) {
    return this.http
      .get(
        BaseUrl.API_ENDPOINT + "user/v1/driver/todayreport/" + driverId,
        this.jwt()
      )
      .pipe(catchError(this.handleError("driverTodayReport", [])));
  }

  // bus owner chart api

  getBusOwnerChartDetails(org_id, interval, startDate, endDate) {
    let start,
      end = null;
    if (interval == "custom") {
      start = `${startDate.year}-${
        (startDate.month < 10 ? "0" : "") + startDate.month
      }-${startDate.day}`;
      end = `${endDate.year}-${
        (endDate.month < 10 ? "0" : "") + endDate.month
      }-${endDate.day}`;
    }
    return this.http
      .get(
        BaseUrl.API_ENDPOINT +
          `user/v1/dashboard/organisation/${org_id}?timeIterval=${interval}&start=${start}&end=${end}`,
        this.jwt()
      )
      .pipe(catchError(this.handleError("dashboardChart", [])));
  }

  getBusManagerDashboard(org_id) {
    return this.http
      .get(
        BaseUrl.API_ENDPOINT + `user/v1/dashboard/busmanager/summary/${org_id}`,
        this.jwt()
      )
      .pipe(catchError(this.handleError("BusManagerDashboard", [])));
  }

  //Access Token JWT
  public jwt() {
    // create authorization header with jwt token
    let currentUser = JSON.parse(localStorage.getItem("currentUser"));

    if (currentUser && currentUser.token) {
      const headerDict = {
        Authorization: currentUser.token,
      };
      const requestOptions = {
        headers: new HttpHeaders(headerDict),
      };

      return requestOptions;
    }
  }
}
