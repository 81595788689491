import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../../services/index";
import { ErrorService } from "src/app/services/error.service";
import { ToastrManager } from "ng6-toastr-notifications";
import { Select2OptionData } from "ng2-select2";
import { toInteger } from "@ng-bootstrap/ng-bootstrap/util/util";

@Component({
  selector: "app-add",
  templateUrl: "./add.component.html",
  styleUrls: ["./add.component.css"]
})
export class AddComponent implements OnInit {
  allBusSeatsLayouts: any;
  seatlayout_details: any = {};
  post_seatlayout_data: any = {};
  busesListData: any;
  meridian = true;
  _allBuses: Array<Select2OptionData>;
  public startValue: string;
  public selected: string;

  public seat_details: any = [];
  public last_row = [];
  public sortableOptionsList = [];
  public each_row: any;
  public diff_count: any;
  public totallast: any;
  user_session: any;
  orgId: any;
  constructor(
    private userService: UserService,
    public toastr: ToastrManager,
    private errorService: ErrorService,
    private router: Router
  ) {}

  ngOnInit() {
    this.user_session = this.userService.newSessionForUser();
    if (this.user_session.user.roles != "isSuperAdmin") {
      this.orgId = this.user_session.user.organisation_ids[0].id;
    }
    this.startValue = "";
    this.seatlayout_details = {
      bus_id: "",
      seat_type: "",
      total_seat: "",
      layout: "",
      layout_type: "",
      last_seat: "",
      no_sleeper: ""
    };
    this.getAllbuses();
    this.getAllbusSeatsLayouts();
  }
  getAllbusSeatsLayouts(): any {
    this.userService.getAllBusSeatLayout().subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.allBusSeatsLayouts = Object(t).data;
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }      
    });
  }

  // GET all buses
  getAllbuses() {
    this.userService.getAllBuses().subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.busesListData = Object(t).data;
        var objBusesListArray = [];
        objBusesListArray.push({
          id: "",
          text: "All Bus"
        });
        this.busesListData.forEach(t => {
          var _childrens = [];
          t.buses_id.forEach(bus => {
            _childrens.push({ id: bus.id, text: bus.busName });
          });
          if (
            this.orgId == t.id &&
            this.user_session.user.roles != "isSuperAdmin"
          ) {
            objBusesListArray.push({
              id: t.id,
              text: t.organisation_name,
              children: _childrens
            });
          }
          if (this.user_session.user.roles === "isSuperAdmin") {
            objBusesListArray.push({
              id: t.id,
              text: t.organisation_name,
              children: _childrens
            });
          }
        });
        this._allBuses = objBusesListArray;
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }

  public changed(e: any): void {
    this.selected = e.value;
    
  }


  lastno: any;
  public showGeneratedSeatLayout(e: any): void {
    var count = e.total_seat;
    var inner = e.total_seat;
    if (e.last_seat === "null") {
      this.lastno = "0";
    } else {
      this.lastno = e.last_seat;
    }
    // var lastno = e.last_seat;
    var layout_types = e.layout_type;
    var _sleeper = e.no_sleeper;

    var scope_sleepers = "0";
    if (e.seat_type == "Seater && Sleeper") {
      scope_sleepers = _sleeper;
    }

    this.last_row = [];
    if (this.lastno > 0) {
      inner = parseInt(count) - parseInt(this.lastno);
      var inners = this.lastno;
      var _totallast: any;
      this.totallast = 0;
      if (this.lastno < 5) {
        _totallast = parseInt("5") - parseInt(this.lastno);
      } else {
        _totallast = 0;
      }
      this.totallast = parseInt(this.lastno) + parseInt(_totallast);
      var tsum = parseInt(count) + parseInt(_totallast);
      var s = 1;
      for (var i = inner; i < tsum; i++) {
        if (i < count) {
          if (scope_sleepers > inner) {
            var sleeper = "sleeper";
          } else {
            if (e.seat_type == "Sleeper") {
              var sleeper = "sleeper";
            } else {
              var sleeper = "seater";
            }
          }
        } else {
          sleeper = "sleeper1";
        }

        this.last_row.push({
          bus: i,
          type: sleeper,
          seat_no: "E" + s
        });
        s++;
      }
    }

    var layout_divide = [0, 2, 3, 3, 4];

    var layout_name = layout_types.split("-");
    layout_name = layout_name;
    var layout_type = parseInt(layout_name[1]);
    var layout_count = layout_divide[layout_type];
    var _each_row = toInteger(parseInt(inner) / layout_count);
    var total_count = _each_row * layout_count;
    var diff_count = toInteger(parseInt(inner) - total_count);
    this.each_row = _each_row;
    this.diff_count = diff_count;
    

    var layout_var = [];
    layout_var[1] = ["left_1", "right_2"];
    layout_var[2] = ["left_1", "right_1", "right_2"];
    layout_var[3] = ["left_1", "left_2", "right_2"];
    layout_var[4] = ["left_1", "left_2", "right_1", "right_2"];
    var seat_no = [];
    seat_no = ["A", "B", "C", "D"];
    let scope_layout_var = layout_var;
    let scope_seat_details = [];
    scope_seat_details["left_1"] = [];
    scope_seat_details["left_2"] = [];
    scope_seat_details["right_1"] = [];
    scope_seat_details["right_2"] = [];

    for (let i = 0; i < layout_var[layout_type].length; i++) {
      var seat_count = _each_row;

      if (i == layout_var[layout_type].length - 1) {
        seat_count = _each_row + diff_count;
      }
      if (i == 0) {
        s = 0;
      } else {
        s = seat_count * i;
      }
      for (var j = 0; j < seat_count; j++) {
        var seat = 1 + j;
        var num = s + j;
        if (parseInt(scope_sleepers) > num) {
          var sleeper = "sleeper";
        } else {
          if (e.seat_type == "Sleeper") {
            var sleeper = "sleeper";
          } else {
            var sleeper = "seater";
          }
        }
        scope_seat_details[layout_var[layout_type][i]].push({
          bus: num,
          type: sleeper,
          seat_no: seat_no[i] + seat
        });
      }
    }
    this.seat_details = scope_seat_details;
  }

  public onSubmit(event: any) {
    this.showGeneratedSeatLayout(event);
    var layout_name = event.layout_type.split("-");
    var layout_type = parseInt(layout_name[1]);
    var layout_var = [];
    layout_var[1] = ["left_1", "right_2"];
    layout_var[2] = ["left_1", "right_1", "right_2"];
    layout_var[3] = ["left_1", "left_2", "right_2"];
    layout_var[4] = ["left_1", "left_2", "right_1", "right_2"];
    var sortingLog0 = [];
    var sortingLog1 = [];
    var sortingLog2 = [];
    var sortingLog3 = [];
    var sortingLog4 = [];
    this.sortableOptionsList = [
      sortingLog0,
      sortingLog1,
      sortingLog2,
      sortingLog3,
      sortingLog4
    ];
    for (var i = 0; i < layout_var[layout_type].length; i++) {
      var seat_count = this.each_row;

      if (i == parseInt(layout_var[layout_type].length) - 1) {
        seat_count = this.each_row + this.diff_count;
      }
      for (var j = 0; j < seat_count; j++) {
        this.sortableOptionsList[i].push(
          this.seat_details[layout_var[layout_type][i]][j]
        );

        //var logEntry = $scope.rawScreens[i];
        //$scope.sortingLog1[i] =$scope.test1[i];
      }
    }
    for (var i = 0; i < this.totallast; i++) {

      this.sortableOptionsList[4].push(this.last_row[i]);
    }
    this.saveLayout(event);
  }

  public saveLayout = function(e) {
    var layoutss = {};
    var lastno = e.last_seat;
    var lastnos = 0;
    for (var i = 0; i < 5; i++) {
      //var logEntry = $scope.rawScreens[i];
      layoutss[i] = this.sortableOptionsList[i];
    }

    if (lastno == undefined) {
      lastnos = 0;
    } else {
      lastnos = lastno;
    }
    //alert($scope.lastnos);
    var _data = {
      bus_id: this.selected,
      total_seat: e.total_seat,
      layout_type: e.layout_type,
      last_seat: e.last_seat,
      bus_type: e.seat_type,
      layout: layoutss,
      no_sleeper: e.no_sleeper
    };
    

    this.userService.postBusSeatLayout(_data).subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.toastr.successToastr("Bus layout created succesfully!");
        // this.router.navigate(["bus_management/view_all"]);
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  };

  keyPress(event: any) {
    const pattern = /[0,1,2,3,4,5\+\-\ ]/;

    let inputChar = String.fromCharCode(event.charCode);
    if (event.keyCode != 1 && !pattern.test(inputChar)) {
      event.preventDefault();
    }
  }
}
