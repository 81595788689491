import { NgModule, ModuleWithProviders } from "@angular/core";
import { CommonModule } from '@angular/common';
import {
  DataFilterPipe,
  FilterByRolePipe,
  RoleFilterPipe,
  DataOrgFilterPipe,
  BusManagementPipe,
  DateComparePipe,
  TimeFormat,
  BusTicketsFilterPipe,
  DriverDutyListFilterPipe,
  BoardDropPipe,
  DataBookerFilter
} from "./data-filter.pipe";
import { ValueArrayPipe } from "./array_object.pipe";
import { NgbDateParserFormatter } from "@ng-bootstrap/ng-bootstrap";
import { NgbDateMomentParserFormatter } from "./NgbDateMomentParserFormatter";

@NgModule({
  imports: [CommonModule],
  declarations: [
    DataFilterPipe,
    ValueArrayPipe,
    FilterByRolePipe,
    RoleFilterPipe,
    DataOrgFilterPipe,
    BusManagementPipe,
    DateComparePipe,
    TimeFormat,
    BusTicketsFilterPipe,
    DriverDutyListFilterPipe,
    BoardDropPipe,
    DataBookerFilter
  ],
  exports: [
    DataFilterPipe,
    ValueArrayPipe,
    FilterByRolePipe,
    RoleFilterPipe,
    DataOrgFilterPipe,
    BusManagementPipe,
    DateComparePipe,
    TimeFormat,
    BusTicketsFilterPipe,
    DriverDutyListFilterPipe,
    BoardDropPipe,
    DataBookerFilter
  ],
  providers: [
    {
      provide: NgbDateParserFormatter,
      useFactory: () => { return new NgbDateMomentParserFormatter("DD-MM-YYYY") }
    }
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule
    };
  }
}
