import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrManager } from "ng6-toastr-notifications";
import { NgxSpinnerService } from "ngx-spinner";
import { AuthService } from "../services/auth.service";
import { AuthGuard } from "../services/authguard/auth.guard";
import { UserService } from "../services/jwt/user.service";
@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  model: any = {};
  constructor(private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authservice: AuthService,
    private authGuard: AuthGuard,
    public toastr: ToastrManager,
    public userService: UserService,
    private spinner: NgxSpinnerService) { }

  ngOnInit() {
  }

  onChangePasswordSubmit() {
    var formValue = {};
    if (this.model.password != this.model.confirmpassword) {
      this.toastr.errorToastr("Password and confirm password not same", "Error");
    }
    else {
      formValue = {
        password: this.model.password,
      };
      this.spinner.show();
      this.userService.changedPassowrd(formValue).subscribe(
        successData => {
          if (successData) {
            this.spinner.hide();
            this.toastr.successToastr("changed", "Success!");
          } else {
            this.spinner.hide();
            this.toastr.errorToastr(
              "Something went wrongn please try again!!!",
              "Oops!"
            );
          }
        },
        errData => {
          this.spinner.hide();
          this.toastr.errorToastr("Something went wrong ", "error");
          
        }
      );
    }



  }

}
