import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import {AuthGuard } from './services/index';
import { ChangePasswordComponent } from './change-password/change-password.component';

export const Approutes: Routes = [
         {
           path: "",
           component: FullComponent,
           children: [
             {
               path: "",
               redirectTo: "admin",
               pathMatch: "full"
             },
             {
               path: "",
               loadChildren:
                 "./dashboards/dashboard.module#DashboardModule",
               canActivate: [AuthGuard]
             },
             {
               path: "users",
               loadChildren: "./users/users.module#UsersModule",
               canActivate: [AuthGuard]
             },
             {
               path: "change_password",
               component: ChangePasswordComponent,
               canActivate: [AuthGuard]
             },
             {
               path: "busowner",
               loadChildren:
                 "./UserrByRole/busowner/busowner.module#BusownerModule",
               canActivate: [AuthGuard]
             },
             {
               path: "agents",
               loadChildren:
                 "./UserrByRole/agent/agent.module#AgentModule",
               canActivate: [AuthGuard]
             },
            //  {
            //   path: "agents/ticketview",
            //   loadChildren:
            //     "./UserrByRole/view-agent-ticket/viewAgentTicket.module#viewAgentTicketModule",
            //   canActivate: [AuthGuard]
            // },
             {
               path: "busmanager",
               loadChildren:
                 "./UserrByRole/manager/manager.module#ManagerModule",
               canActivate: [AuthGuard]
             },
             {
               path: "busdriver",
               loadChildren:
                 "./UserrByRole/driver/driver.module#DriverModule",
               canActivate: [AuthGuard]
             },
             {
               path: "drivers",
               loadChildren: "./driver/driver.module#DriverModule",
               canActivate: [AuthGuard]
             },
             {
               path: "amenities",
               loadChildren:
                 "./amenities/amenities.module#AmenitiesModule",
               canActivate: [AuthGuard]
             },

             {
                path: "help",
                loadChildren: "./help/help.module#HelpModule",
                canActivate: [AuthGuard]
             },
             {
               path: "route",
               loadChildren: "./route_details/route.module#RouteModule",
               canActivate: [AuthGuard]
             },
             {
               path: "bus_management",
               loadChildren:
                 "./bus_management/bus-managment.module#BusManagmentModule",
               canActivate: [AuthGuard]
             },
             {
               path: "seat_layout",
               loadChildren: "./seatloyout/seat.module#SeatModule",
               canActivate: [AuthGuard]
             },

             {
               path: "customers",
               loadChildren:
                 "./customers/customers.module#CustomersModule"
             },
             {
               path: "booking_details",
               loadChildren:
                 "./booking-details/booking-details.module#BookingDetailsModule"
             },
             {
               path: "cancellation",
               loadChildren:
                 "./cancellation/cancellation.module#CancellationModule",
               canActivate: [AuthGuard]
             },
             {
               path: "component",
               loadChildren:
                 "./component/component.module#ComponentsModule"
             },
             {
               path: "cards",
               loadChildren: "./cards/cards.module#CardsModule"
             },
             {
               path: "icons",
               loadChildren: "./icons/icons.module#IconsModule"
             },
             {
               path: "forms",
               loadChildren: "./form/forms.module#FormModule"
             },
             {
               path: "tables",
               loadChildren: "./table/tables.module#TablesModule"
             },
             {
               path: "charts",
               loadChildren: "./charts/charts.module#ChartModule"
             },
             {
               path: "widgets",
               loadChildren: "./widgets/widgets.module#WidgetsModule"
             },
             {
               path: "ecom",
               loadChildren: "./ecommerce/ecom.module#EcomModule"
             },
             {
               path: "timeline",
               loadChildren: "./timeline/timeline.module#TimelineModule"
             },
             {
               path: "extra-component",
               loadChildren:
                 "./extra-component/extra-component.module#ExtraComponentModule"
             },
             {
               path: "apps",
               loadChildren: "./apps/apps.module#AppsModule"
             },
             {
               path: "maps",
               loadChildren: "./maps/maps.module#MapsModule"
             },
             {
               path: "sample-pages",
               loadChildren:
                 "./sample-pages/sample-pages.module#SamplePagesModule"
             }
           ]
         },
         {
           path: "",
           component: BlankComponent,
           children: [
             {
               path: "authentication",
               loadChildren:
                 "./authentication/authentication.module#AuthenticationModule"
             }
           ]
         },
         {
           path: "**",
           redirectTo: "/authentication/404"
         }
       ];
