import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services";
import { ToastrManager } from "ng6-toastr-notifications";
import {
  NgbAccordionConfig,
  NgbActiveModal,
  NgbModal,
} from "@ng-bootstrap/ng-bootstrap";
import { ErrorService } from "src/app/services/error.service";
import { Routes, ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-view-agent-ticket",
  templateUrl: "./view-agent-ticket.component.html",
  styleUrls: ["./view-agent-ticket.component.css"],
})
export class ViewAgentTicketComponent implements OnInit {
  ticketsDetails: any[] = [];
  agentId: string;
  model: any = {};
  isView_Edit: any;
  modalObj: any = {};
  constructor(
    private agentHelper: UserService,
    public toastr: ToastrManager,
    config: NgbAccordionConfig,
    public activeModal: NgbActiveModal,
    private router: Router,
    private modalService: NgbModal,
    private errorService: ErrorService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    if (this.model.action == "view") {
      this.Load();
    } else {
      this.isView_Edit = this.model.action;
      this.modalObj = {
        firstName: this.model.item.firstName,
        id: this.model.item.id,
        lastName: this.model.item.lastName,
        mobileNumber: this.model.item.mobileNumber,
        emailAddress: this.model.item.emailAddress,
        agentCommision: this.model.item.agentCommision,
      };
    }
  }

  Load() {
    this.agentHelper.getTicketHistry(this.model.item.id).subscribe((t) => {
      if (!!t && t.constructor === Object) {
        this.ticketsDetails = Object(t).data;
        this.isView_Edit = this.model.action;
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }

  onSubmit() {
    this.agentHelper.updateCustomer(this.modalObj).subscribe((t) => {
      if (!!t && t.constructor === Object) {
        this.toastr.successToastr("Succesfully update Users  !");
        this.activeModal.dismiss();
        location.reload();
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
          return true;
        };
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }

  paid(customerTrip) {
    this.agentHelper.UpdateComissionStatus(customerTrip).subscribe((t) => {
      if (!!t && t.constructor === Object) {
        this.Load();
        this.toastr.successToastr("Successfuly changed");
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }
}
