import * as $ from 'jquery';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from "ng6-toastr-notifications";
import { ChartsModule } from 'ng2-charts/ng2-charts';
import {
  CommonModule,
  LocationStrategy,
  HashLocationStrategy
} from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { Routes, RouterModule } from '@angular/router';

import { DataFilterPipe } from "./shared/data-filter.pipe";

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AgmCoreModule } from '@agm/core';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';

import {SharedModule} from './shared/shared.module';

import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';

import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';

import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner.component';


import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { MomentModule } from 'angular2-moment';
import { Select2Module } from 'ng2-select2';

//services
// import { AuthService, AuthGuard,UserService, CommonService } from './services/index'; // services
import { AuthService, AuthGuard, UserService} from './services/index'; // services
import { SessionService} from './services/session/session.service'; // services

import { from } from 'rxjs';
import { HttpErrorHandler } from './services/http-error-handler.service';
import { EditUpdateBusTypeComponent } from './bus_management/edit-update-bus-type/edit-update-bus-type.component';
import { ModalBusComponent } from './bus_management/modal-bus/modal-bus.component';
import { ModalComponent } from './amenities/modal/modal.component'; // amenities modal
import { ModalRouteComponent } from './route_details/modal-route/modal-route.component';
import { NgxSpinnerModule } from "ngx-spinner";



import { ModalDriverComponent } from './driver/modal-driver/modal-driver.component';
import { AssignDutyComponent } from './driver/assign-duty/assign-duty.component';
import { ModalBookingDetailsComponent } from './booking-details/modal-booking-details/modal-booking-details.component';

// Import your AvatarModule
import { AvatarModule } from 'ngx-avatar';
import { CustomerModalComponent } from './customers/customerModal/customer-modal/customer-modal.component';
import { BusonwerComponent } from './dashboards/admin-modal/busonwer/busonwer.component';
import { RoutesComponent } from './dashboards/admin-modal/routes/routes.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ViewAgentTicketComponent } from './UserrByRole/view-agent-ticket/view-agent-ticket.component';
import { EditModalUserbyroleComponent } from './UserrByRole/edit-modal-userbyrole/edit-modal-userbyrole.component';
import { ChartsComponent, RevenueChartsComponent } from './dashboards/charts/charts.component';

import { AddComponent } from './seatloyout/add-old/add.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true,
  wheelSpeed: 1,
  wheelPropagation: true,
  minScrollbarLength: 20
};

@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    FullComponent,
    BlankComponent,
    NavigationComponent,
    BreadcrumbComponent,
    SidebarComponent,
    EditUpdateBusTypeComponent,
    ModalBusComponent,
    ModalComponent,
    ModalRouteComponent,
    ModalDriverComponent,
    AssignDutyComponent,
    ChartsComponent,
    // AddComponent,
    AddComponent,
    RevenueChartsComponent,
    ModalBookingDetailsComponent,
    BusonwerComponent,
    RoutesComponent,
    CustomerModalComponent,
    ChangePasswordComponent,
    ViewAgentTicketComponent,
    EditModalUserbyroleComponent
    ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    HttpClientModule,
    NgbModule.forRoot(),
    SlimLoadingBarModule.forRoot(),
    RouterModule.forRoot(Approutes, { useHash: false }),
    PerfectScrollbarModule,
    ToastrModule.forRoot(),
    SharedModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBUb3jDWJQ28vDJhuQZxkC0NXr_zycm8D0" 
    }),
    MomentModule,
    Select2Module,
    NgxSpinnerModule,
    AvatarModule
  ],
  providers: [
    AuthService,
    AuthGuard,
    UserService,
    SessionService,
    HttpErrorHandler,
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    },
    {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }
  ],
  entryComponents: [
    EditUpdateBusTypeComponent,
    ModalBusComponent,
    ModalComponent,
    ModalRouteComponent,
    ModalDriverComponent,
    ModalBookingDetailsComponent,
    AssignDutyComponent,
    CustomerModalComponent,
    BusonwerComponent,
    RoutesComponent,
    ViewAgentTicketComponent,
    EditModalUserbyroleComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
