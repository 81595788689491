import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'booking-charts',
  // templateUrl: './charts.component.html',
  styleUrls: ['./charts.component.css'],
  template:`<canvas baseChart [datasets]="barChartData1" [labels]="barChartLabels" [options]="barChartOptions"
  [legend]="barChartLegend" [chartType]="barChartType">
</canvas>`
})
export class ChartsComponent implements OnInit {
  constructor() {}
  // This is line chart
  // bar chart
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    barThickness: 10
  };
  public barChartLabels: string[] = [
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2015',
    '2016',
    '2017',
    '2016',
    '2017'
  ];
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData1: any[];// = [
  //   { data: [0, 55, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Total Booking' }
  // ];
  public barChartColors: Array<any> = [
    { backgroundColor: '#36bea6' },
    { backgroundColor: '#2962FF' }
  ];

  ngOnInit() {
    this.barChartData1 = [
      { data: [0, 55, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Total Booking' }
    ];
  }
}


@Component({
  selector: 'revenue-charts',
  styleUrls: ['./charts.component.css'],
  template:`<canvas baseChart [datasets]="barChartData2" [labels]="barChartLabels" [options]="barChartOptions"
  [legend]="barChartLegend" [chartType]="barChartType" (chartHover)="chartHovered($event)"
  [colors]="barChartColors" (chartClick)="chartClicked($event)">
</canvas>`
})
export class RevenueChartsComponent implements OnInit {
  constructor() {}
  // This is line chart
  // bar chart
  public barChartOptions: any = {
    scaleShowVerticalLines: false,
    responsive: true,
    barThickness: 10
  };
  public barChartLabels: string[] = [
    '2011',
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2015',
    '2016',
    '2017',
    '2016',
    '2017'
  ];
  public barChartType = 'bar';
  public barChartLegend = true;

  public barChartData2: any[] = [
    { data: [0, 177547, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Total Revenue' }
  ];
  public barChartColors: Array<any> = [
    { backgroundColor: '#36bea6' },
    { backgroundColor: '#2962FF' }
  ];

  ngOnInit() {

    
  }


  // chartClicked = ($event: any) => {
  // };

  // chartHovered = ($event:any)=>{
  // }
}


