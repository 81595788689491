import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';

import { ErrorService } from './error.service';
import { Router } from '@angular/router';

export type HandleError = <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable()
export class HttpErrorHandler {
  private modalRef: NgbModalRef;
  constructor(
    private errorService: ErrorService,
    private router: Router,
  ) {}

  /** Create handleError function that already knows the service name */
  createHandleError = (serviceName = "") => <T>(
    operation = "operation",
    result = {} as T
  ) => this.handleError(serviceName, operation, result);

  /**
   * @param serviceName: name of the data service
   * @param operation: name of the failed operation
   * @param result: optional value to return as the observable result
   */
  handleError<T>(serviceName = "", operation = "operation", result = {} as T) {
    return (error: HttpErrorResponse): Observable<T> => {
      // Todo -> Send the error to remote logging infrastructure
      // console.error(error); // log to console instead

      const message =
        error.error.message instanceof ErrorEvent
          ? error.error.message
          : `{"error code": ${error.status}, "body": "${error.error.message}"}`;
      // error.error.message :{error_code: error.status, body:error.error.message};
      if (error.error.message == "Invalid Token!") {
        localStorage.removeItem("currentUser");
        // this.activeModal.dismiss();
        this.modalRef.close();
        this.router.navigate(["/authentication/login"]);
      } else if (error.error.message) {
        // result = error.error.message;
        // return error.error.message;
      }

      // Todo -> Transforming error for user consumption
      // this.errorService.errorMessage = `${serviceName} -> ${operation} failed.\n  Message: ${message}`;
      this.errorService.errorMessage = message;
      // -> Return a safe result.
      return of(result);
    };
  }
}
