import { environment } from "../../environments/environment.prod";

export class BaseUrl {
  // public static API_ENDPOINT = `${window.location.protocol}//${window.location.hostname}:1337/`
        //  public static API_ENDPOINT = "http://192.168.1.9:1337/";
            //  public static API_ENDPOINT = "http://localhost:1337/";
             public static API_ENDPOINT = environment.API_URL;
            //public static API_ENDPOINT = "http://voomnow.afterworksol.com:1337/";
        //  public static API_ENDPOINT = "https://voomnow.herokuapp.com/";
         //  public static API_ENDPOINT = "https://voomapi.herokuapp.com/";
       }
//
