import { Component, OnInit } from '@angular/core';
import {
  NgbModal,
  NgbActiveModal,
  ModalDismissReasons,
  NgbModalRef
} from "@ng-bootstrap/ng-bootstrap";
@Component({
  selector: 'app-busonwer',
  templateUrl: './busonwer.component.html',
  styleUrls: ['./busonwer.component.css']
})
export class BusonwerComponent implements OnInit {
    model:any=[];
  constructor(public activeModal: NgbActiveModal,) { }

  ngOnInit() {

  }

}
