import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal, ModalDismissReasons, NgbModalRef, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { ToastrManager } from "ng6-toastr-notifications";
import { UserService } from "../../services/index";
import { ErrorService } from "src/app/services/error.service";
@Component({
  selector: "app-edit-update-bus-type",
  templateUrl: "./edit-update-bus-type.component.html",
  styleUrls: ["./edit-update-bus-type.component.css"]
})
export class EditUpdateBusTypeComponent implements OnInit {
  model: any = {};
  busTypeValue = {};
  private modalRef: NgbModalRef;
  constructor( public activeModal: NgbActiveModal, private modalService: NgbModal, private userService: UserService,
    public toastr: ToastrManager,
    private errorService: ErrorService
  ) {

  }
  public onbusTypeSubmit() {
      this.busTypeValue = {
       id:this.model.id,
        bustype: this.model.bustype
      };
    this.userService.updateBusType(this.busTypeValue).subscribe(t => {
      if (!!t && t.constructor === Object) {
        this.toastr.successToastr("Succesfully added bus type !");
        this.activeModal.dismiss();
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }

  ngOnInit() {


  }
}
