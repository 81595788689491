import { Component, OnInit } from "@angular/core";
import { UserService } from "../../services/index";
import { ToastrManager } from "ng6-toastr-notifications";
import { ErrorService } from "src/app/services/error.service";
import { Select2Module } from "ng2-select2";
import { Router } from "@angular/router";
import { Select2OptionData } from "ng2-select2";
import { NgbCalendar, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";
import * as _ from "lodash";
import * as moment from "moment";
import {
  NgbModal,
  NgbActiveModal,
  ModalDismissReasons,
  NgbModalRef,
} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: "app-assign-duty",
  templateUrl: "./assign-duty.component.html",
  styleUrls: ["./assign-duty.component.css"],
})
export class AssignDutyComponent implements OnInit {
  model: any;
  busesListData: any;
  managersListData: any = [];
  routeList: any;
  _allBuses: Array<Select2OptionData>;
  public startValue: string;
  public selected: string;
  assign_duty_data: any;
  assign_duty_data_form: any;
  user_session: any;
  orgId: any;
  assignedToId: any;
  constructor(
    private userService: UserService,
    public toastr: ToastrManager,
    private errorService: ErrorService,
    public activeModal: NgbActiveModal,
    private router: Router
  ) {}

  ngOnInit() {
    this.allGetBusManagers();
    this.assign_duty_data = {
      bus_id: "",
      route_id: "",
      driver_id: "",
      organisation_ids: "",
      duty_assigned_by: "",
      duty_assignedTo: "",
      duty_fromdate: "",
      duty_todate: "",
    };

    this.user_session = this.userService.newSessionForUser();

    // this.orgId = this.user_session.user.organisation_ids[0].id;
    if (this.user_session.user.roles != "isSuperAdmin") {
      this.orgId = this.user_session.user.organisation_ids[0].id;
    }

    this.getAllbuses();
  }

  // GET all buses
  getAllbuses() {
    this.userService.getAllBuses().subscribe((t) => {
      if (!!t && t.constructor === Object) {
        this.busesListData = Object(t).data;
        var objBusesListArray = [];
        objBusesListArray.push({
          id: "0",
          text: "Select Bus",
        });
        this.busesListData.forEach((t) => {
          var _childrens = [];
          t.buses_id.forEach((bus) => {
            _childrens.push({ id: bus.id, text: bus.busName });
          });

          if (
            this.orgId == t.id &&
            this.user_session.user.roles != "isSuperAdmin"
          ) {
            objBusesListArray.push({
              id: t.id,
              text: t.organisation_name,
              children: _childrens,
            });
          }
          if (this.user_session.user.roles === "isSuperAdmin") {
            objBusesListArray.push({
              id: t.id,
              text: t.organisation_name,
              children: _childrens,
            });
          }
        });
        this._allBuses = objBusesListArray;
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }

  //TODO: get all Manager

  allGetBusManagers() {
    this.userService.getAllBusManagers().subscribe((t) => {
      if (!!t && t.constructor === Object) {
        var _temp = [];
        this.managersListData = [];
        var manager = Object(t).data;
        _.forEach(manager, (x) => {
          _.filter(x.user_ids, (item) => {
            _temp.push(item);
          });
        });
        this.managersListData = _temp;
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }

  public changed(e: any): void {
    this.selected = e.value;
    this.getRouteById(this.selected);
  }

  public onChangeAssignTo(assignedId) {
    this.assignedToId = assignedId;
  }

  //For construction call method
  getRouteById(id) {
    this.userService.getRouteByBusId(id).subscribe((t) => {
      if (!!t && t.constructor === Object) {
        this.routeList = Object(t).data;
      } else {
        if (this.errorService.errorMessage) {
          var msg = JSON.parse(this.errorService.errorMessage);
          this.toastr.errorToastr(msg.body);
        }
      }
    });
  }

  public onSubmit() {
    this.assign_duty_data_form = {
      bus_id: this.selected,
      route_id: this.assign_duty_data.route_id,
      driver_id: this.model.item.id,
      duty_assigned_by: this.user_session.user.id,
      organisation_ids: this.model.item.organisation_ids,
      is_assigned: true,
    };
    this.userService
      .assigndutyToDriver(this.assign_duty_data_form)
      .subscribe((t) => {
        if (!!t && t.constructor === Object) {
          this.toastr.successToastr("Successsfull Duty Assigned");
          this.activeModal.dismiss();
        } else {
          if (this.errorService.errorMessage) {
            var msg = JSON.parse(this.errorService.errorMessage);
            this.toastr.errorToastr(msg.body);
          }
        }
      });
  }
}
